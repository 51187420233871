import React from 'react'
import { IGatsbyPage } from 'gatsby/internal'
import NotFoundPage from 'Views/404/NotFoundPage'
import { graphql } from 'gatsby'

export default function NotFound(props: IGatsbyPage) {
  return <NotFoundPage {...props} />
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
