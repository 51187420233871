import React from 'react'
import { IGatsbyPage } from 'gatsby/internal'
import Illustration from 'Assets/icon-cogs.inline.svg'
import AltPageLayout from 'Components/layout/AltPageLayout'
import BaseContainer from 'Components/BaseContainer/BaseContainer'
import Touchable from 'Components/touchables/Touchable'
import Text from 'Components/texts/Text'
import Menu from 'Components/layout/Menu'
import styles from './NotFoundPage.module.scss'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { useMaybeChangeLanguageOnInit } from 'Utils/common'

const NotFoundPage = ({ path }: IGatsbyPage) => {
  const { t } = useTranslation()

  useMaybeChangeLanguageOnInit()

  return (
    <>
      <Menu />
      <AltPageLayout
        className={styles.root}
        seo={t('notFound.seo', { returnObjects: true })}
        path={path}
        title={t('notFound.heading')}
        footerProps={{ type: 'simple' }}
        firstSectionHasLabel={false}
      >
        <BaseContainer className={styles.content} contentClassName={styles.contentInner}>
          <Illustration className={styles.illustration} />
          <Text>{t('notFound.notice')}</Text>
          <Touchable link="/">
            <Text className={styles.link} variant="sm">
              {t('notFound.link')}
            </Text>
          </Touchable>
        </BaseContainer>
      </AltPageLayout>
    </>
  )
}

export default NotFoundPage
